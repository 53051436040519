import { useEffect, useState } from 'react';
import moment from 'moment';
import { isNil } from 'lodash';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { DateFilterOption } from '../../../types/filters/DateFilterOption';
import { DateRange } from '../../../types/filters/DateRange';
import { dateRangeFilterOptions } from '../../../constants/date-range.filter';
import { getRangeFromDateOption } from '../../../utils/amr-pipeline.utils';
import classnames from 'classnames';
import { ExpandClearToggle } from './ExpandClearToggle';
import { ClickOutside } from '../ClickOutside';
import { FilterDropDown } from './FilterDropDown';
import classNames from 'classnames';
import { validateYear } from '../../../utils/amr-pipeline-filter.utils';

interface FilterDateTimeRangeProps {
    acceptableOptions?: DateFilterOption[];
    defaultExpanded?: boolean;
    selectedFilterOption?: DateFilterOption;
    value?: DateRange;
    minYear?: number;
    maxYear?: number;
    formatTitle?: boolean;
    onSelectedDateChange: (option: DateFilterOption) => {};
    onCustomDateChange: (option: DateRange) => {};
    title: string;
    onClearAll: () => void;
    isApplied?: boolean;
}

const DateFormat = 'YYYY';
const EmptyValue = { from: undefined, to: undefined }

export function FilterYearsDateRange({
    acceptableOptions = dateRangeFilterOptions.vintage(),
    defaultExpanded = false,
    selectedFilterOption,
    value,
    onSelectedDateChange,
    onCustomDateChange,
    formatTitle = true,
    title,
    onClearAll,
    minYear = 2010,
    maxYear = moment().year(),
    isApplied = false,
}: FilterDateTimeRangeProps) {
    function getFilterOptions() {
        return acceptableOptions.map(option => {
            const dateRange = getRangeFromDateOption(option);

            if (!dateRange) {
                return option;
            }

            const { from, to } = dateRange;
            const fromTitle = from?.year();
            const toTitle = to?.year();

            const selectedTitle = fromTitle === toTitle ? `${fromTitle}` : `${fromTitle} - ${toTitle}`;

            return {
                ...option,
                selectedTitle,
                from: from?.toDate(),
                to: to?.toDate(),
            };
        });
    }

    const dateOptions: DateFilterOption[] = getFilterOptions();
    const [expanded, setExpanded] = useState(defaultExpanded);

    const [internalValue, setInternalValue] = useState(value as DateFilterOption);

    const [error, setError] = useState({ from: '', to: '' });

    const [selectedOption, setSelectedOption] = useState(selectedFilterOption?.key);

    useEffect(() => {
        setSelectedOption(selectedFilterOption?.key);
    }, [selectedFilterOption]);

    useEffect(() => {
        const fromYear = moment(value?.from, DateFormat).year().toString();
        const toYear = moment(value?.to, DateFormat).year().toString();

        setInternalValue(prevState => ({
            ...prevState,
            ...(value?.from && { from: moment(value.from).format(DateFormat) }),
            ...(value?.to && { to: moment(value.to).format(DateFormat) }),
        }));

        setError({ from: validateYear(fromYear) || validateRange(value!), to: validateYear(toYear) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const { to, from } = internalValue;

        if (!from && !to && selectedFilterOption) {
            return handleClearAll();
        }

        if (from || to) {
            if (!from) {
                const newDateValues = {
                    to: moment(to, DateFormat).endOf('year').toDate(),
                    from: moment(to, DateFormat).toDate(),
                };
                setInternalValue({ ...internalValue, from: to });

                onCustomDateChange(newDateValues);
            }
            if (!to) {
                const newDateValues = {
                    to: moment(from, DateFormat).endOf('year').toDate(),
                    from: moment(from, DateFormat).toDate(),
                };
                setInternalValue({ ...internalValue, to: from });

                onCustomDateChange(newDateValues);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const isFilterSelected = () => {
        if (!selectedFilterOption) {
            return false;
        }

        return !isNil(internalValue?.from) && !isNil(internalValue?.to);
    };

    const handleOptionChange = (option: DateFilterOption) => {
        setSelectedOption(option.key);
        setInternalValue(option);
        onSelectedDateChange(option);

        const from = option.from ? new Date(option.from) : null;
        const to = option.to ? new Date(option.to) : null;

        onCustomDateChange({ from, to });
    };

    const handleClearAll = () => {
        onClearAll();
        setSelectedOption(undefined);
        setInternalValue(EmptyValue as DateFilterOption);
        onCustomDateChange(EmptyValue);

        setError({ from: '', to: '' });
    };

    const validateRange = (range: DateFilterOption | DateRange) => {
        if (range.from && range.to) {
            const from = moment(range.from, DateFormat).year();
            const to = moment(range.to, DateFormat).year();

            if (from > to) {
                return 'From year must be less than To year';
            }
        }

        return '';
    }

    const handleYearsFromChange = (from?: string) => {
        setInternalValue({ ...internalValue, from });

        if (from?.length === 4) {
            const errorFrom = validateYear(from) || validateRange(internalValue);

            const fromDate = moment(from, DateFormat).toDate();
            onCustomDateChange({ from: fromDate, to: value?.to });
            setError({ ...error, from: errorFrom });
        } else if(from?.length === 0) {
            setError({ ...error, from: '' });
        }
    };

    const handleYearsToChange = (to?: string) => {
        setInternalValue({ ...internalValue, to });

        if (to?.length === 4) {
            const errorTo = validateYear(to);

            const toDate = moment(to, DateFormat).endOf('year').toDate();
            onCustomDateChange({ from: value?.from, to: toDate });
            setError({ ...error, to: errorTo });
        }
    };

    function renderOptions() {
        return dateOptions.map(option => (
            <li
                key={option.key}
                onClick={() => handleOptionChange(option)}
                className={classNames({
                    selected: option.key === selectedFilterOption?.key,
                })}
            >
                {option.title}
            </li>
        ));
    }

    function renderTitle() {
        const { from, to } = value!;
        const selectedFilterOption = dateOptions.find(o => o.key === selectedOption);
        const selectedTitle = selectedFilterOption?.selectedTitle || selectedFilterOption?.title;

        if (!from || !to) {
            if (selectedFilterOption) {
                return `${title}: ${selectedTitle}`;
            }

            return formatTitle ? `${title}: All` : title;
        }

        const formattedFrom = moment(from).year();
        const formattedTo = moment(to).year();


        return selectedOption === dateRangeFilterOptions.Custom.key
            ? `${title}: ${formattedFrom} - ${formattedTo}`
            : selectedFilterOption?.formatTitle ? `${title}: ${selectedFilterOption?.formatTitle(selectedTitle)}` : `${title}: ${selectedTitle}`;
    }

    const renderCustomView = () => {
        const { from, to } = internalValue;

        return (
            <div className="custom-view-item">
                <div className="custom-view-item-body">
                    <div className="flex-row">
                        <div className="flex-none">
                            <label htmlFor="inputFor" className="form-label">
                                From
                            </label>
                            <MaskedInput
                                id="inputFrom"
                                name="from"
                                mask={createNumberMask({
                                    prefix: '',
                                    integerLimit: 4,
                                    allowDecimal: false,
                                    includeThousandsSeparator: false,
                                })}
                                guide={true}
                                type="text"
                                value={String(from)}
                                className={classnames({
                                    'form-control': true,
                                    'is-invalid': error.from,
                                })}
                                placeholder="YYYY"
                                onChange={e => handleYearsFromChange(e.target.value)}
                            />
                            {!!error.from && <div className="form-error">{error.from}</div>}
                            {
                                <span className="year-range">
                                    Year range: [{minYear} - {maxYear}]
                                </span>
                            }
                        </div>
                        <span className="separator-line">—</span>
                        <div className="flex-none">
                            <label className="form-label">To</label>
                            <MaskedInput
                                id="inputFrom"
                                name="from"
                                mask={createNumberMask({
                                    prefix: '',
                                    integerLimit: 4,
                                    allowDecimal: false,
                                    includeThousandsSeparator: false,
                                })}
                                guide={true}
                                type="text"
                                value={String(to)}
                                className={classnames({
                                    'form-control': true,
                                    'is-invalid': error.to,
                                })}
                                placeholder="YYYY"
                                onChange={e => handleYearsToChange(e.target.value)}
                            />
                            {!!error.to && <div className="form-error">{error.to}</div>}
                            {
                                <span className="year-range">
                                    Year range: [{minYear} - {maxYear}]
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const isSelected = isFilterSelected();

    return (
        <ClickOutside className="control-filter-select" onClick={() => setExpanded(false)}>
            <div
                className={classnames('control-filter-select-btn', {
                    expanded,
                    error: error.from || error.to,
                    applied: isApplied && isSelected,
                })}
            >
                <div onClick={() => setExpanded(!expanded)}>{renderTitle()}</div>
                <ExpandClearToggle
                    filterSelected={isSelected}
                    onExpand={() => setExpanded(!expanded)}
                    onClear={handleClearAll}
                />
            </div>
            {expanded && (
                <FilterDropDown
                    className={classnames('control-filter-content-year-range-input',
                        {
                            'control-filter-content-date': dateOptions.length > 1,
                        },
                        {
                            'control-filter-content-datepicker': selectedOption === dateRangeFilterOptions.Custom.key,
                        },
                    )}
                    expanded={expanded}
                    value={value}
                >
                    <div className="control-filter-range-list">
                        <button className="btn-link" disabled={!isSelected} onClick={handleClearAll}>
                            Reset to default
                        </button>

                        {dateOptions.length > 1 && <ul className="control-filter-date-list">{renderOptions()}</ul>}
                    </div>
                    {selectedOption === dateRangeFilterOptions.Custom.key && renderCustomView()}
                </FilterDropDown>
            )}
        </ClickOutside>
    );
}
