import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { Preloader } from '../common';

interface DropDownButtonProps {
    children: React.ReactNode;
    title: React.ReactNode;
    className?: string;
    expanded: boolean;
    focused: boolean;
    disabled?: boolean;
    loading?: boolean;
    onClick: (expanded: boolean) => void;
}

export const DropDownButton = ({
    children,
    title,
    className,
    expanded,
    focused,
    disabled = false,
    loading = false,
    onClick,
}: DropDownButtonProps) => {
    return (
        <div
            className={cn('custom-drop-down form-control position-relative', className, { 'is-open': expanded, 'select-focused': focused, disabled })}
            onClick={disabled ? undefined : () => onClick(!expanded)}
        >
            {loading && <Preloader small inProgress={true} text="Processing…" fullScreen={false}/>}
            <div className="drop-down-btn">
                {title}
                <IconSVG className={cn({ 'is-open': expanded })} name="icon-expand" width={16} height={16} />
            </div>
            {expanded && children}
        </div>
    );
}
