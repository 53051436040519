import React, { useState } from 'react';
import { kebabCase } from 'lodash';
import { RadioOption } from '../../../types/filters/RadioOption';
import { ClickOutside } from '../../common/';
import { RadioButtonGroup } from '../../controls';
import classNames from 'classnames';
import { EmptyPlaceholder } from '../../common';
import { FilterDropDown } from './FilterDropDown';
import { ExpandClearToggle } from './ExpandClearToggle';

export interface FilterRadioProps<FilterType> {
    options: RadioOption<FilterType>[];
    value?: FilterType;
    defaultExpanded?: boolean;
    title: string | React.ReactNode;
    formatTitle?: boolean;
    onChangeItemSelection: (i: RadioOption<FilterType>) => void;
    onClearAll?: () => void;
    className?: string;
    isApplied?: boolean;
    disabled?: boolean;
}

export function FilterRadio<FilterType>({
    options,
    value,
    defaultExpanded = false,
    title,
    onChangeItemSelection,
    formatTitle = true,
    onClearAll,
    className,
    isApplied = false,
    disabled = false
}: FilterRadioProps<FilterType>) {
    const radioOptions = [
        {
            text: "All",
            value: null,
        },
        ...options,
    ];
    const [expanded, setExpanded] = useState(defaultExpanded);
    const selectedOption = radioOptions.find(o => o.value === value);
    const isOptionSelected = !!selectedOption && selectedOption.value !== null;

    function renderTitle() {
        if (!formatTitle) {
            return title;
        }

        if (selectedOption) {
            return <>
                {title}:&nbsp;<span className="selected-data">{selectedOption.text}</span>
            </>;
        }

        return `${title}: All`;
    }

    function renderOptions() {
        if (!options.length) return <EmptyPlaceholder textView={true} />;

        return (
            <RadioButtonGroup
                options={radioOptions.map(o => ({ label: o.text, value: o.value }))}
                value={selectedOption?.value}
                onChange={(value: any) => onChangeItemSelection(value)}
                disabled={false}
            />
        );
    }

    function renderClearSelectTrigger() {
        return (
            <button className="btn-link" disabled={!isOptionSelected} onClick={onClearAll}>
                Reset to default
            </button>
        );
    }

    return (
        <ClickOutside className={classNames("control-filter-select", `control-filter-${kebabCase(className)}`)} onClick={() => setExpanded(false)}>
            <div
                className={classNames("control-filter-select-btn", { expanded, applied: isApplied && isOptionSelected, disabled })}
                onClick={() => setExpanded(!expanded)}
            >
                {renderTitle()}
                <ExpandClearToggle filterSelected={isOptionSelected} onExpand={() => setExpanded(!expanded)} onClear={onClearAll} />
            </div>
            {!disabled && expanded && (
                <FilterDropDown
                    className={classNames('control-filter-content-select')}
                    expanded={expanded}
                    value={options}
                >
                    {onClearAll && renderClearSelectTrigger()}
                    {renderOptions()}
                </FilterDropDown>
            )}
        </ClickOutside>
    );
}
