import { useField } from 'formik';
import cn from 'classnames';
import { BaseFieldProps } from './InputField';

export const CheckboxField = ({ ...props }: BaseFieldProps) => {
    const [field] = useField({ name: props.name, type: 'checkbox' });

    return (
        <div className={cn("data-item-row", props.wrapperClassName)}>
            <div className="data-item-col data-item-col-title">
                <div
                    className="checkbox"
                    onClick={(e) => e.stopPropagation()}
                >
                    <input {...field} id={field.name} type="checkbox" />
                    <label htmlFor={field.name} className={cn('checkbox-lbl', { 'checkbox-lbl-text': props.label })}>
                        {props.label}
                    </label>
                </div>
            </div>
            
        </div>
    );
}
