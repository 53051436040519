import moment from "moment";
import { constants } from "../../constants/constants";
import { DayModifiers, DayPicker, Matcher } from "react-day-picker";
import React, { ChangeEvent, useState } from "react";
import { ClickOutside } from "../common/ClickOutside";
import IconSVG from "../../styles/svg-icons";
import { CustomDatePicker } from "../common/date-range-custon-view/CustomDatePicker";

interface DayPickerInputProps {
    name?: string;
    className?: string;
    selectedDate?: Date;
    minimumDate?: Date;
    maximumDate?: Date;
    placeholder?: string;
    dateFormat?: string;
    disabled?: boolean;
    onDayChange: (day?: Date | string, dayRaw?: string) => void
    disabledDays?: Matcher[];
    allowWeekends?: boolean;
    onBlur?: (e: ChangeEvent) => void
    toMonth?: Date;
    emptyIfNull?: boolean;
    withCustomView?: boolean;
}

export const DayPickerInput = ({
    selectedDate,
    dateFormat = constants.dateFormat,
    className,
    disabledDays = [],
    minimumDate,
    maximumDate,
    onBlur,
    placeholder,
    disabled,
    toMonth,
    onDayChange,
    emptyIfNull = false,
    allowWeekends = true,
    withCustomView = false,
}: DayPickerInputProps
) => {
    const [month, setMonth] = useState<Date>(selectedDate ?? new Date());
    const [currentDate, setCurrentDate] = useState<Date>(selectedDate ?? new Date());
    const [showDayPicker, setShowDayPicker] = useState(false);
    const [inputValue, setInputValue] = useState(
        selectedDate
            ? moment(selectedDate).format(dateFormat)
            : emptyIfNull
                ? ''
                : moment(new Date()).format(dateFormat),
    );

    const handleOnDayClick = (date: Date, modifiers?: DayModifiers) => {
        if (modifiers?.disabled) {
            return;
        }

        setCurrentDate(date);
        setInputValue(moment(date).format(dateFormat));
        onDayChange(date, moment(date).format());
        setShowDayPicker(false);
    };

    const onRawInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value && e.target.value.trim();
        const newCurrentMoment = moment(value, dateFormat, true);

        if (newCurrentMoment.isValid()) {
            const date = newCurrentMoment.toDate();
            setCurrentDate(date);
            setMonth(date);
            onDayChange(date, moment(date).format());
        } else {
            onDayChange(value === '' ? value: undefined, value);
        }

        setInputValue(e.target.value);
    }

    const renderNavIcon = () => <IconSVG name="icon-expand" width={16} height={16} />;

    return (
        <ClickOutside onClick={() => setShowDayPicker(false)}>
            <div className="rdp-input">
                <input
                    className={className}
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={onRawInputChange}
                    onFocus={() => setShowDayPicker(true)}
                    disabled={disabled}
                    onBlur={onBlur}
                />
                {showDayPicker ?
                <div className="rdp-overlay">
                    {withCustomView ? (
                        <CustomDatePicker
                            date={currentDate}
                            from={currentDate}
                            to={currentDate}
                            onDayClick={handleOnDayClick}
                        />
                    ) : (
                        <DayPicker
                            className="rdp-single"
                            toMonth={toMonth}
                            month={month}
                            onMonthChange={setMonth}
                            showOutsideDays={true}
                            selected={currentDate}
                            onDayClick={handleOnDayClick}
                            modifiers={{
                                disabled: [
                                    ...disabledDays,
                                    {
                                        dayOfWeek: allowWeekends ? [] : [0, 6]
                                    },
                                    ...(maximumDate ? [{ after: maximumDate }] : []),
                                    ...(minimumDate ? [{ before: minimumDate }] : []),
                                ]
                            }}
                            components={{
                                IconLeft: renderNavIcon,
                                IconRight: renderNavIcon
                            }}
                        />
                    )}
                </div>: null}
            </div>
        </ClickOutside>
    )
}
