import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { ClickOutside } from '../../ClickOutside';
import { bwicDateFilterOptions as options, constants, errorMessages } from '../../../../constants';
import { CustomDateRangePicker } from '../../date-range-custon-view/CustomDateRangePicker';
import { FormError } from '../../../controls';
import { ExpandClearToggle } from '../ExpandClearToggle';

export default class DateRangeSelector extends Component {
    state = {
        expanded: false,
        selectFocus: false,
        customFrom: '',
        customTo: '',
        visibleCustomView: false,
        errorFrom: '',
        errorTo: ''
    };

    show = () => {
        if (!this.props.disabled) {
            this.setState({
                customClassName: false,
                expanded: true,
                selectFocus: true,
                customFrom: this.props.customDateRange.from,
                customTo: this.props.customDateRange.to,
                visibleCustomView: this.props.selectedDateOption.key === options.custom.key
            });
        }
    };

    hide = () => {
        const { selectedDateOption } = this.props;
        const { visibleCustomView, errorFrom, errorTo, customFrom, customTo } = this.state;
        const isCustom = visibleCustomView && selectedDateOption.key === options.custom.key;

        const isDateOrEmpty = date => date ? moment(date, true).isValid() : true;

        if (errorFrom || errorTo || (
            isCustom && ((!customFrom && !customTo) || !isDateOrEmpty(customFrom) || !isDateOrEmpty(customTo))  )) {
            this.props.onReset();
            this.setState({ customFrom: '', customTo: '' });
        }

        this.setState({ expanded: false, visibleCustomView: false, errorFrom: '', errorTo: '' });
    };

    parseCustomDateOrDefault = (date, defaultValue) => {
        if (date) {
            const dateMoment = moment(date, 'MM/DD/YYYY', true).startOf('day');
            return dateMoment.isValid() ? dateMoment.toDate() : defaultValue;
        }
        return date;
    };

    getRangeList = () => {
        const { acceptedOptions } = this.props;
        const dateFormat = 'M/D/YY';
        const rangeList = [];
        const today = moment().format(dateFormat);
        const monday = moment().day(1).format(dateFormat);
        const friday = moment().day(5).format(dateFormat);
        const weekAgo = moment().subtract(1, 'week').format(dateFormat);
        const monthAgo = moment().subtract(1, 'month').format(dateFormat);
        const yearAgo = moment().subtract(1, 'year').format(dateFormat);

        const mapping = [
            {
                key: options.todayAndUpcoming.key,
                action: () => rangeList.push({ ...options.todayAndUpcoming, selectedTitle: `Today and Upcoming` })
            },
            {
                key: options.current.key,
                action: (customSelectedTitle, customTitle) => rangeList.push({
                    ...options.current,
                    selectedTitle: customSelectedTitle || options.current.title,
                    title: customTitle || options.current.title
                })
            },
            {
                key: options.thisWeek.key,
                action: () => rangeList.push({ ...options.thisWeek, selectedTitle: `This Week (${monday} - ${friday})` })
            },
            {
                key: options.lastWeek.key,
                action: () => rangeList.push({ ...options.lastWeek, selectedTitle: `Last Week (${weekAgo} - ${today})` })
            },
            {
                key: options.lastMonth.key,
                action: () => rangeList.push({ ...options.lastMonth, selectedTitle: `Last Month (${monthAgo} - ${today})` })
            },
            {
                key: options.lastYear.key,
                action: () => rangeList.push({ ...options.lastYear, selectedTitle: `Last Year (${yearAgo} - ${today})` })
            },
            {
                key: options.unspecified.key,
                action: () => rangeList.push({ ...options.unspecified, selectedTitle: 'All' })
            },
            {
                key: options.custom.key,
                action: () => rangeList.push({ ...options.custom, selectedTitle: `${monday} - ${friday}` })
            },
            {
                key: options.today.key,
                action: () => rangeList.push({ ...options.today, selectedTitle: options.today.title })
            },
            {
                key: options.currentInventory.key,
                action: () => rangeList.push({ ...options.currentInventory, selectedTitle: options.currentInventory.title })
            },
        ];
        acceptedOptions.forEach(o => mapping.find(m => m.key === o.key)?.action(o.selectedTitle, o.title));
        return rangeList;
    };

    formatTitle = filterTitle => {
        const { selectedDateOption, customDateRange } = this.props;
        const rangeList = this.getRangeList();
        const format = value => filterTitle ? `${filterTitle}: ${value}` : value;

        if (this.state.visibleCustomView && selectedDateOption.key !== options.custom.key) {
            return format('Custom');
        }
        if (selectedDateOption.key === options.custom.key) {
            const from = this.parseCustomDateOrDefault(customDateRange.from);
            const to = this.parseCustomDateOrDefault(customDateRange.to);
            if (from && to) {
                return format(`${moment(from).format('M/D/YY')} - ${moment(to).format('M/D/YY')}`);
            }
            return format('Custom');
        }
        for (let i = 0; i < rangeList.length; i++) {
            if (selectedDateOption.key === rangeList[i].key) {
                return format(rangeList[i].selectedTitle);
            }
        }
        return '';
    };

    validateFromDate = (date, toDate) => {
        const { disabledDays } = this.props;

        if (!date) {
            return errorMessages.datePickerInvalidFromDate;
        }
        if (date.getFullYear() < constants.datePickerMinYear || date.getFullYear() > constants.datePickerMaxYear) {
            return errorMessages.datePickerInvalidFromDate;
        }
        if (toDate && moment(date).isAfter(toDate, 'day')) {
            return errorMessages.fromDateBiggerThenToDate;
        }
        if (
            disabledDays &&
            disabledDays.after &&
            new Date(date).setHours(0, 0, 0, 0) > new Date(disabledDays.after).setHours(0, 0, 0, 0)
        ) {
            return errorMessages.datePickerInvalidFromDate;
        }
        return '';
    };

    validateToDate = (date) => {
        const { disabledDays } = this.props;
        if (!date) {
            return errorMessages.datePickerInvalidToDate;
        }
        if (date.getFullYear() < constants.datePickerMinYear || date.getFullYear() > constants.datePickerMaxYear) {
            return errorMessages.datePickerInvalidToDate;
        }
        if (
            disabledDays &&
            disabledDays.after &&
            new Date(date).setHours(0, 0, 0, 0) > new Date(disabledDays.after).setHours(0, 0, 0, 0)
        ) {
            return errorMessages.datePickerInvalidToDate;
        }
        return '';
    };

    handleDateFromManualChange = e => {
        const { customTo } = this.state;
        const fromValue = e.target.value;
        const from = this.parseCustomDateOrDefault(fromValue);
        const to = this.parseCustomDateOrDefault(customTo);
        const errorFrom = !String(fromValue).includes('_') && fromValue && this.validateFromDate(from, to);
        this.setState({ customFrom: from || e.target.value, errorFrom });
        if (from && to && moment(from).isAfter(to)) {
            this.setState({ customFrom: from });
        }
        this.props.onCustomDateChange({ from, to });
    };

    handleDateToManualChange = e => {
        const { customFrom } = this.state;
        const toValue = e.target.value;
        const from = this.parseCustomDateOrDefault(customFrom);
        const to = this.parseCustomDateOrDefault(toValue);
        const errorTo = !String(toValue).includes('_') && toValue && this.validateToDate(to);
        const errorFrom = customFrom && this.validateFromDate(from, to);
        this.setState({ customTo: to || e.target.value, errorTo, errorFrom });
        if (from && to && moment(from).isAfter(to)) {
            this.setState({ customTo: to });
        }
        this.props.onCustomDateChange({ from, to });
    };

    handleDayClick = range => {
        const errorFrom = this.validateFromDate(range.from, range.to);
        const errorTo = range.to && this.validateToDate(range.to);

        this.setState({
            customFrom: range.from,
            customTo: range.to,
            errorFrom,
            errorTo
        });
        this.props.onCustomDateChange(range);
        this.props.onSelectedDateChange(options.custom);
    };

    handleOptionsChange = option => {
        const isOptionCustom = option.key === options.custom.key;
        if (!isOptionCustom) {
            this.hide();
            this.props.onSelectedDateChange({ key: option.key, title: option.title }, false);
        } else {
            this.setState({ visibleCustomView: true });
        }
    };

    handleClickOutside = () => {
        if (this.state.expanded) {
            if (this.props.selectedDateOption.key === options.custom.key) {
                this.hide();
                return;
            }
            this.hide();
        }
        this.setState({ selectFocus: false });
    };

    handleDateManualBlur = () => {
        this.props.onSelectedDateChange(options.custom);
    };

    renderCustomView = () => {
        const { customFrom, customTo, errorTo, errorFrom } = this.state;
        const from = this.parseCustomDateOrDefault(customFrom);
        const to = this.parseCustomDateOrDefault(customTo);
        const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
        const dateFormat = 'MM/DD/YYYY';
        return (
            <div className="custom-view-item">
                <div className="custom-view-item-body">
                    <div className="flex-row">
                        <div className="flex-none">
                            <label htmlFor="inputFor" className="form-label">From</label>
                            <MaskedInput
                                name="from"
                                mask={dateMask}
                                guide={true}
                                type="text"
                                value={from ? moment(from).format(dateFormat) : (customFrom || '')}
                                className={cn('form-control', { 'is-invalid': !!errorFrom })}
                                placeholder={dateFormat}
                                onChange={this.handleDateFromManualChange}
                                onBlur={this.handleDateManualBlur}
                            />
                            <FormError message={errorFrom} />
                        </div>
                        <span className="separator-line">—</span>
                        <div className="flex-none">
                            <label className="form-label">To</label>
                            <MaskedInput
                                name="from"
                                mask={dateMask}
                                guide={true}
                                type="text"
                                value={to ? moment(to).format(dateFormat) : (customTo || '')}
                                className={cn('form-control', { 'is-invalid': !!errorTo })}
                                placeholder={dateFormat}
                                onChange={this.handleDateToManualChange}
                                onBlur={this.handleDateManualBlur}
                            />
                            <FormError message={errorTo} />
                        </div>
                    </div>
                    <CustomDateRangePicker
                        from={from}
                        to={to}
                        onDayClick={this.handleDayClick}
                        disabledDays={this.props.disabledDays}
                    />
                </div>
            </div>
        );
    };

    renderDateOptions = (dateOptions) => {
        return dateOptions.map(option =>
            <li
                key={option.key}
                onClick={() => this.handleOptionsChange(option)}
                className={
                    (option.key === this.props.selectedDateOption.key && !this.state.visibleCustomView) ||
                        (this.state.visibleCustomView && option.key === options.custom.key) ? 'selected' : ''
                }
            >
                {option.title}
            </li>
        );
    };

    renderRestyled = () => {
        const dateOptions = this.getRangeList();
        const { selectedDateOption, title, disabled, isApplied, onReset, selectedDefault = options.unspecified } = this.props;
        const isSelected = selectedDateOption.key !== selectedDefault.key;

        return (
            <div className={cn("control-filter-select", this.props.customClassName)}>
                <div
                    className={cn(
                        "control-filter-select-btn", {
                        applied: isApplied && isSelected,
                        expanded: this.state.expanded,
                        disabled
                    })}
                    onClick={this.show}
                >
                    {this.formatTitle(title)}
                    <ExpandClearToggle filterSelected={isSelected} onExpand={this.show} onClear={onReset} />
                </div>
                {
                    this.state.expanded &&
                    <ClickOutside onClick={this.handleClickOutside}>
                        <div className={cn(
                            "control-filter-content control-filter-content-date", {
                            "control-filter-content-datepicker": this.state.visibleCustomView
                        })}
                        >
                            <div className="control-filter-range-list">
                                <ul className="control-filter-date-list">
                                    {this.renderDateOptions(dateOptions)}
                                </ul>
                            </div>
                            {this.state.visibleCustomView && this.renderCustomView()}
                        </div>
                    </ClickOutside>
                }
            </div>
        );
    }

    render = () => {
        const dateOptions = this.getRangeList();
        const { selectedDateOption, restyled, disabled } = this.props;

        if (!selectedDateOption || !dateOptions) {
            return null;
        }

        if (restyled) {
            return this.renderRestyled();
        }

        const containerClassNames = cn('select-date-range',
            {
                'select-expanded': this.state.expanded,
                'select-focused': this.state.selectFocus,
                disabled
            }
        );

        return (
            <div className={containerClassNames}>
                <div
                    className="form-control form-control-sm form-select" onClick={this.show}>
                    {this.formatTitle()}
                </div>
                {
                    this.state.expanded &&
                    <ClickOutside onClick={this.handleClickOutside}>
                        <div className="select-lookup">
                            <ul className="select-lookup-items">
                                {this.renderDateOptions(dateOptions)}
                            </ul>
                            {this.state.visibleCustomView && this.renderCustomView()}
                        </div>
                    </ClickOutside>
                }
            </div>
        );
    };
}

DateRangeSelector.defaultProps = {
    restyled: false,
    isApplied: false,
    acceptedOptions: options.toDefaultArray(),
    disabled: false,
    disabledDays: []
};

DateRangeSelector.propTypes = {
    selectedDateOption: PropTypes.object.isRequired,
    customDateRange: PropTypes.object.isRequired,
    onCustomDateChange: PropTypes.func.isRequired,
    onSelectedDateChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};



