import { useDispatch, useSelector } from 'react-redux';
import { createFilterActions } from '../../../actions/filter.actions';
import { BwicFilterType, TFilterType } from '../../../types/filters/FilterState';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { AppState } from '../../../types/state/AppState';
import { useEffect, useState } from 'react';
import { FiltersState } from '../../../types/state/FiltersState';
import { UserConfigFilter } from '../../../types/user-config/UserConfigFilter';
import { apiUtils, isRequestNone, jsonUtils } from '../../../utils';
import { pageConfigActions } from '../../../actions/page-config.actions';
import { pageConfigService } from '../../../services/page-config.service';
import { PageConfig } from '../../../types/page-config/PageConfig';
import { RequestState } from '../../../constants/request-state';
import { PageConfigList } from '../../../types/state/entities/PageConfigState';
import { errorActions } from '../../../actions';

interface IPageConfigFilterActions {
    addFilter: (filter: UserConfigFilter) => void;
    updateFilter: (filter: UserConfigFilter) => void;
    defaultFlagChange: (referenceName: string, isDefault: boolean) => void;
    deleteFilter: (referenceName: string) => void;
}

export const usePageConfig = (pageConfigType?: PageConfigType, readonly: boolean = false, filterType?: BwicFilterType) => {
    const dispatch = useDispatch();
    const [updateRequestState, setUpdateRequestState] = useState(RequestState.none);

    const filterState = useSelector((s: AppState) => selectFilterState(filterType, s.filters));

    const defaultConfigRequestState = useSelector((state: AppState) => state.entities.pageConfig.defaultConfigRequestState);
    const requestState = useSelector((state: AppState) => state.entities.pageConfig.requestState);
    const defaultConfigsState = useSelector((state: AppState) => state.entities.pageConfig.defaultConfigs);
    const configsState = useSelector((state: AppState) => state.entities.pageConfig.configs);
    const defaultConfig = pageConfigType ? defaultConfigsState[pageConfigType] : undefined;
    const config = pageConfigType ? configsState[pageConfigType] : undefined;

    useEffect(() => {
        if (isRequestNone(requestState) && !readonly) {
            (async () => {
                dispatch(pageConfigActions.getConfigs.request());
                try {
                    const configs = await pageConfigService.getConfigs();
                    const normalizedConfigs: PageConfigList = apiUtils.normalize(
                        configs,
                        configItem => configItem.page,
                        configItem => ({
                            page: configItem.page,
                            columns: configItem.columns.filter(c => c.available),
                            filters: jsonUtils.tryParse(configItem.filters) ?? []
                        })
                    );
                    dispatch(pageConfigActions.getConfigs.success({ configs: normalizedConfigs }))
                } catch (e) {
                    dispatch(pageConfigActions.getConfigs.failure())
                }
            })();
        }
    }, [requestState, dispatch, readonly])

    useEffect(() => {
        if (!readonly && pageConfigType && defaultConfig?.page !== pageConfigType) {
            (async () => {
                dispatch(pageConfigActions.getDefaultConfig.request({ configType: pageConfigType }));
                try {
                    const defaultConfig = await pageConfigService.getDefaultConfigs(pageConfigType);
                    dispatch(pageConfigActions.getDefaultConfig.success({
                        configType: pageConfigType,
                        config: {
                            ...defaultConfig,
                            columns: defaultConfig.columns.filter(c => c.available),
                        }
                    }))
                } catch (e) {
                    dispatch(pageConfigActions.getDefaultConfig.failure({ configType: pageConfigType }))
                }
            })();
        }
    }, [pageConfigType, dispatch, readonly, defaultConfig?.page]);


    const saveColumns = async (config: PageConfig) => {
        if (pageConfigType) {
            try {
                setUpdateRequestState(RequestState.request);
                dispatch(pageConfigActions.saveColumns.request({ configType: pageConfigType }));
                const result = await pageConfigService.saveConfig(pageConfigType, config);
                dispatch(pageConfigActions.saveColumns.success({
                    configType: pageConfigType,
                    columns: result.columns.filter(c => c.available)
                }));
                setUpdateRequestState(RequestState.success);
                return result;
            } catch (e) {
                dispatch(pageConfigActions.saveColumns.failure({ configType: pageConfigType }));
                dispatch(errorActions.unexpectedError(e));
                setUpdateRequestState(RequestState.failure);
            }
        }
    }

    const addFilter = async (newFilter: UserConfigFilter) => {
        const configFilters = config?.filters

        if (filterType && configFilters && pageConfigType && filterState) {
            const filterActions = createFilterActions(filterType);
            const { filter } = filterState;

            try {

                // unselect all other default filters if new default
                const updatedFilters = configFilters
                    .map(f => ({
                        ...f,
                        default: newFilter.default ? false : f.default
                    }))

                await updateFilterConfig([...updatedFilters, newFilter]);
                dispatch(filterActions.setFilterByReferenceName(newFilter.referenceName, filter));
            } catch (e) {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    }

    const updateFilter = async (changedFilter: UserConfigFilter) => {
        const configFilters = config?.filters
        if (filterType && configFilters && pageConfigType && filterState) {
            const filterActions = createFilterActions(filterType);
            const { filter } = filterState;

            try {
                const newFilters = configFilters
                    .map(f => f.referenceName === changedFilter.referenceName 
                        ? changedFilter 
                        : {...f, default : changedFilter.default ? false: f.default}); // update default state

                await updateFilterConfig(newFilters);

                dispatch(filterActions.setFilterByReferenceName(changedFilter.referenceName, filter));
            } catch (e) {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    }

    const defaultFlagChange = async (referenceName: string, isDefault: boolean) => {
        const configFilters = config?.filters
        if (filterType && filterState && configFilters && pageConfigType) {
            try {
                const newFilters = configFilters.map(f => {
                    if (f.referenceName === referenceName) {
                        return { ...f, default: isDefault };
                    }
                    return { ...f, default: false };
                });
                await updateFilterConfig(newFilters);
            } catch (e) {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    }

    const deleteFilter = async (referenceName: string) => {
        const configFilters = config?.filters
        try {
            if (filterType && filterState && configFilters && pageConfigType) {
                const filterActions = createFilterActions(filterType);
                const newFilters = configFilters.filter(f => f.referenceName !== referenceName)
                await updateFilterConfig(newFilters);
                if (filterState.selectedFilterReferenceName === referenceName) {
                    dispatch(filterActions.resetSelectedFilter());
                    dispatch(filterActions.updateFilterState());
                }
            }
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }

    const updateFilterConfig = async (newFilters: UserConfigFilter[]) => {
        if (pageConfigType) {
            await pageConfigService.saveFilters(pageConfigType, newFilters);
            const newConfigs = {
                ...configsState,
                [pageConfigType]: {
                    ...configsState[pageConfigType],
                    filters: newFilters
                }
            }
            dispatch(pageConfigActions.getConfigs.success({ configs: newConfigs }));
        }
    }

    const saveAlerts = async (newFilters: UserConfigFilter[]) => {
        try {
            setUpdateRequestState(RequestState.request);
            await updateFilterConfig(newFilters);
            setUpdateRequestState(RequestState.success);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setUpdateRequestState(RequestState.failure);
        }
    }

    const filterActions: IPageConfigFilterActions = { addFilter, updateFilter, defaultFlagChange, deleteFilter }

    return {
        config,
        defaultConfig,
        saveColumns,
        requestState,
        defaultConfigRequestState,
        updateRequestState,
        filterActions,
        saveAlerts
    };
}

function selectFilterState(filterType?: TFilterType, filters?: FiltersState) {
    switch (filterType) {
        case BwicFilterType.BwicMonitor:
            return filters?.bwicMonitor;
        case BwicFilterType.Portfolio:
            return filters?.portfolio;
        case BwicFilterType.Inventory:
            return filters?.inventory;
        case BwicFilterType.BDInventory:
            return filters?.bdInventory;
    }
}
