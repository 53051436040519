import React, { useEffect, useState } from "react";
import moment from "moment";
import { isNil } from "lodash";
import { DateAfter, Matcher, DateInterval } from "react-day-picker";
import { DateRange } from "../../../types/filters/DateRange";
import { ClickOutside } from "../ClickOutside";
import { constants, errorMessages, pipelineFilters } from "../../../constants";
import { FilterRangeOption } from "../../../types/filters/FilterRangeOption";
import { DateFilterOption } from "../../../types/filters/DateFilterOption";
import { CustomDateRangePicker } from "../date-range-custon-view/CustomDateRangePicker";
import classnames from "classnames";
import { dateRangeFilterOptions as options } from "../../../constants/date-range.filter";
import { dateTimeUtils } from "../../../utils";
import { FilterDropDown } from "./FilterDropDown";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getRangeFromDateOption } from "../../../utils/amr-pipeline.utils";
import { filterUtils, validateDateFormat } from "../../../utils/filtering/filter.utils";
import { FilterButton } from './FilterButton';
import { DropDownButton } from '../../controls/DropDownButton';

const dateFormat = "MM/DD/YYYY";

interface Props {
    acceptableOptions?: DateFilterOption[];
    defaultExpanded?: boolean;
    selectedFilterOption?: DateFilterOption;
    customDateRange: DateRange;
    customYearsRange?: FilterRangeOption;
    minSelectYear?: number;
    title: string;
    maxRangeValue?: number;
    isApplied?: boolean;
    buttonView?: 'filter' | 'chart';
    disabledDays?: Matcher;
    onSelectedDateChange: (option: DateFilterOption) => void;
    onCustomDateChange: (option: DateRange) => void;
    onYearsRangeChange?: (option: FilterRangeOption) => void;
    onClearAll?: () => void;
    disabled?: boolean;
}

const defaultErrors = {
    year: { from: '', to: '' },
    date: { from: '', to: '' },
};

export function FilterDateRange({
    acceptableOptions = options.dateOptions(),
    defaultExpanded = false,
    selectedFilterOption,
    customYearsRange,
    customDateRange,
    title,
    minSelectYear = 2010,
    maxRangeValue = pipelineFilters.maxRangeValue,
    isApplied = false,
    buttonView = 'filter',
    disabledDays,
    onSelectedDateChange,
    onYearsRangeChange,
    onCustomDateChange,
    onClearAll,
    disabled = false,
}: Props) {
    const dateOptions: DateFilterOption[] = getFilterOptions();
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [customDates, setCustomDates] = useState({
        from: customDateRange.from ? moment(customDateRange.from).format(dateFormat) : undefined,
        to: customDateRange.to ? moment(customDateRange.to).format(dateFormat) : undefined,
    });
    const [errors, setErrors] = useState(defaultErrors);

    const [isCustomViewVisible, setCustomViewVisible] = useState(
        selectedFilterOption === options.Custom
    );
    const [isYearsRangeVisible, setYearsRangeVisible] = useState(
        selectedFilterOption === options.YearsRange
    );
    const selected = isFilterSelected();
    const isCustomOptionEmpty =
        !(customYearsRange?.from || customYearsRange?.to) &&
        !(customDateRange?.from || customDateRange?.to);
    const isResetDisabled = !selected && isCustomOptionEmpty;
    const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    const hasErrors = Object.values(errors.date).some(v => !!v) || Object.values(errors.year).some(v => !!v);

    useEffect(() => {
        setCustomViewVisible(options.Custom.key === selectedFilterOption?.key);
        setYearsRangeVisible(
            options.YearsRange.key === selectedFilterOption?.key
        );
        if (!customYearsRange?.from && !customYearsRange?.to)
            setErrors((errors) => ({ ...errors, year: defaultErrors.year }));
    }, [expanded, selectedFilterOption, customYearsRange]);

    useEffect(() => {
        if (!customDateRange.from && !customDateRange.to) {
            setCustomDates({ from: "", to: "" });
            setErrors((errors) => ({ ...errors, date: defaultErrors.date }));
        }
    }, [customDateRange.from, customDateRange.to]);

    useEffect(() => {
        if (isCustomOptionEmpty && selectedFilterOption && !selectedFilterOption.pureOption) {
            return onClearAllHandler();
        }

        if (!expanded && hasErrors) {
            onClearAllHandler();
            return;
        }

        if (!expanded) {
            return;
        }

        if (onYearsRangeChange) {
            if (customYearsRange?.from || customYearsRange?.to) {
                if (!customYearsRange.from) {
                    const newInputValues = {
                        to: customYearsRange.to,
                        from: 0,
                    };
                    onYearsRangeChange(newInputValues);
                } else if (!customYearsRange.to) {
                    const newInputValues = {
                        from: customYearsRange.from,
                        to: maxRangeValue,
                    };
                    onYearsRangeChange(newInputValues);
                }
            }
        }

        if (isCustomViewVisible) {
            if (customDateRange?.from || customDateRange.to) {
                if (!customDateRange.from) {
                    const newInputValues = {
                        to: customDateRange.to,
                        from: customDateRange.to,
                    };
                    setCustomDates({ ...customDates, from: customDates.to });
                    onCustomDateChange(newInputValues);
                } else if (!customDateRange.to) {
                    const newInputValues = {
                        from: customDateRange.from,
                        to: customDateRange.from,
                    };
                    setCustomDates({ ...customDates, to: customDates.from });
                    onCustomDateChange(newInputValues);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        const { from, to } = customDateRange;

        setCustomDates((prevState) => ({
            ...prevState,
            ...(from && { from: moment(from).format(dateFormat) }),
            ...(to && { to: moment(to).format(dateFormat) }),
        }));
    }, [customDateRange]);

    function getFilterOptions() {
        return acceptableOptions.map(option => {
            const dateRange = getRangeFromDateOption(option);

            if (!dateRange) {
                return option;
            }

            const from = dateRange.from?.format(dateFormat);
            const to = dateRange.to?.format(dateFormat);

            return {
                ...option,
                from,
                to,
            };
        });
    }

    function isFilterSelected() {
        if (!selectedFilterOption || selectedFilterOption.default) {
            return false;
        }

        if ([options.YearsRange.key].includes(selectedFilterOption.key)) {
            return (
                !isNil(customYearsRange?.from) && !isNil(customYearsRange?.to)
            );
        }

        if ([options.Custom.key].includes(selectedFilterOption.key)) {
            return !isNil(customDates.from) && !isNil(customDates.to);
        }

        if (selectedFilterOption.pureOption) {
            return true;
        }

        return (
            !isNil(selectedFilterOption.from) || !isNil(selectedFilterOption.to)
        );
    }

    function handleOptionsChange(option: DateFilterOption) {
        if (option.key !== selectedFilterOption?.key) {
            const isOptionCustom = option.key === options.Custom.key;
            const isYearsRange = option.key === options.YearsRange.key;
            const isDateOption = options
                .dateOptions()
                .some((o) => o.key === option.key);

            if (!isOptionCustom && !isYearsRange && !isDateOption) {
                setExpanded(false);
                onSelectedDateChange(option);
                setErrors(defaultErrors);
            } else if (isYearsRange) {
                onSelectedDateChange(options.YearsRange);
                setYearsRangeVisible(true);
                setCustomViewVisible(false);
                setErrors(defaultErrors);
            } else if (isDateOption && !isOptionCustom) {
                setExpanded(false);
                onSelectedDateChange(option);

                const from = option.from ? new Date(option.from) : null;
                const to = option.to ? new Date(option.to) : null;
                onCustomDateChange({ from, to });
                setErrors(defaultErrors);
            } else {
                onSelectedDateChange(options.Custom);
                setCustomViewVisible(true);
                setYearsRangeVisible(false);
            }
        }
    }

    function handleClickOutside() {
        setExpanded(false);
        setCustomViewVisible(false);
        setYearsRangeVisible(false);
    }

    function handleDayClick(range: DateRange) {
        const errorFrom = filterUtils.validateFromDate(minSelectYear, range.from, range.to);
        const errorTo = filterUtils.validateToDate(minSelectYear, range.to);

        setCustomDates({
            from: range.from
                ? moment(range.from).format(constants.dateShortFormat)
                : undefined,
            to: range.to
                ? moment(range.to).format(constants.dateShortFormat)
                : undefined,
        });

        setErrors({ ...errors, date: { from: errorFrom, to: errorTo } });
        onCustomDateChange({ from: range.from, to: range.to });
    }

    function handleDateToManualChange(e: React.ChangeEvent<HTMLInputElement>) {
        const from =
            dateTimeUtils.parseDate(customDates.from, dateFormat) || null;
        const to =
            dateTimeUtils.parseDate(e.target.value, dateFormat) || null;
        const errorTo = validateDateFormat(e.target.value, dateFormat, errorMessages.datePickerInvalidToDate)
            || filterUtils.validateToDate(minSelectYear, to || e.target.value, disabledDays as DateAfter);
        const errorFrom = validateDateFormat(customDates.from, dateFormat, errorMessages.datePickerInvalidFromDate)
            || filterUtils.validateFromDate(
                minSelectYear,
                from,
                to || e.target.value,
                disabledDays as DateInterval,
            );

        setCustomDates({ ...customDates, to: to || e.target.value });
        setErrors({ ...errors, date: { to: errorTo, from: errorFrom } });

        if (!errorTo && !errorFrom) {
            onCustomDateChange({ from, to });
        }
    }

    function handleDateFromManualChange(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        const from =
            dateTimeUtils.parseDate(e.target.value, dateFormat) || null;
        const to =
            dateTimeUtils.parseDate(customDates.to, dateFormat) || null;
        const errorTo = validateDateFormat(customDates.to, dateFormat, errorMessages.datePickerInvalidToDate)
            || filterUtils.validateToDate(minSelectYear, to, disabledDays as DateAfter);
        const errorFrom = validateDateFormat(e.target.value, dateFormat, errorMessages.datePickerInvalidFromDate)
            || filterUtils.validateFromDate(minSelectYear, from || e.target.value, to, disabledDays as DateInterval);

        setCustomDates({ ...customDates, from: from || e.target.value });
        setErrors({ ...errors, date: { to: errorTo, from: errorFrom } });

        if (!errorTo && !errorFrom) {
            onCustomDateChange({ from, to });
        }

        if (from && to && moment(from).isAfter(to)) {
            setCustomDates({ ...customDates, from });
        }
    }

    function onClearAllHandler() {
        onClearAll && onClearAll();
        setCustomDates({ to: "", from: "" });
        setErrors({ year: { from: "", to: "" }, date: { from: "", to: "" } });
    }

    function handleResetToDefault() {
        const defaultOption = acceptableOptions.find(o => o.default);
        if (defaultOption) {
            handleOptionsChange(defaultOption);
        } else {
            onClearAllHandler();
        }
    }

    function handleCustomYearsRangeChange(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        const newInputValues = {
            ...customYearsRange,
            [e.target.name]: e.target.value.replace("Y", ""),
        };
        const { from, to } = newInputValues;

        setErrors({ ...errors, year: { from: "", to: "" } });

        if (from && to) {
            if (parseFloat(String(from)) > parseFloat(String(to)))
                setErrors({
                    ...errors,
                    year: {
                        from: errorMessages.fromRangeBiggerThenToRange,
                        to: "",
                    },
                });
        }

        if (Number(from) > maxRangeValue) newInputValues.from = maxRangeValue;
        if (Number(to) > maxRangeValue) newInputValues.to = maxRangeValue;

        onYearsRangeChange && onYearsRangeChange(newInputValues);
    }

    ///Render functions

    function renderDateOptions() {
        return dateOptions.map((option) => (
            <li
                key={option.key}
                onClick={() => handleOptionsChange(option)}
                className={
                    (option.key === selectedFilterOption?.key &&
                        !isCustomViewVisible) ||
                        (isCustomViewVisible && option.key === options.Custom.key)
                        ? "selected"
                        : ""
                }
            >
                {option.title}
            </li>
        ));
    }

    function renderTitle() {
        return <FilterDateRangeTitle
            name={title}
            selected={selectedFilterOption}
            customDateRange={customDateRange}
            customYearsRange={customYearsRange}
            maxRangeValue={maxRangeValue}
        />
    }

    function renderYearsRangeView() {
        return (
            <div className="custom-view-year-range control-filter-range-row">
                <div className="form-control-wrapper">
                    <label className="form-label" htmlFor="rangeFrom">
                        From
                    </label>
                    <MaskedInput
                        className={classnames({
                            'form-control': true,
                            'is-invalid': errors.year.from,
                        })}
                        mask={createNumberMask({
                            prefix: "",
                            suffix: "Y",
                            allowDecimal: true,
                            integerLimit: 3,
                            decimalLimit: 2,
                            includeThousandsSeparator: false,
                        })}
                        onChange={handleCustomYearsRangeChange}
                        value={customYearsRange?.from}
                        placeholder="0Y"
                        name="from"
                        id="from"
                    />
                    {!!errors.year.from && (
                        <div className="form-error">{errors.year.from}</div>
                    )}
                </div>
                <span className="dash">{constants.emptyPlaceholder}</span>
                <div className="form-control-wrapper">
                    <label className="form-label" htmlFor="rangeTo">
                        To
                    </label>
                    <MaskedInput
                        className={classnames({
                            'form-control': true,
                            'is-invalid': errors.year.to,
                        })}
                        mask={createNumberMask({
                            prefix: "",
                            suffix: "Y",
                            allowDecimal: true,
                            integerLimit: 3,
                            decimalLimit: 2,
                            includeThousandsSeparator: false,
                        })}
                        onChange={handleCustomYearsRangeChange}
                        value={customYearsRange?.to}
                        placeholder={`${maxRangeValue}Y`}
                        name="to"
                        id="to"
                    />
                    {!!errors.year.to && (
                        <div className="form-error">{errors.year.to}</div>
                    )}
                </div>
            </div>
        );
    }

    function renderCustomView() {
        const from = dateTimeUtils.parseDate(customDates.from, dateFormat);
        const to = dateTimeUtils.parseDate(customDates.to, dateFormat);

        return (
            <div className="custom-view-item">
                <div className="custom-view-item-body">
                    <div className="flex-row">
                        <div className="flex-none">
                            <label htmlFor="inputFor" className="form-label">
                                From
                            </label>
                            <MaskedInput
                                id="inputFrom"
                                name="from"
                                mask={dateMask}
                                guide={true}
                                type="text"
                                value={
                                    from
                                        ? moment(from).format(dateFormat)
                                        : customDates.from ?? ""
                                }
                                className={classnames({
                                    "form-control": true,
                                    "is-invalid": errors.date.from,
                                })}
                                placeholder={dateFormat}
                                onChange={handleDateFromManualChange}
                            />
                            {!!errors.date.from && (
                                <div className="form-error">
                                    {errors.date.from}
                                </div>
                            )}
                        </div>
                        <span className="separator-line">—</span>
                        <div className="flex-none">
                            <label className="form-label">To</label>
                            <MaskedInput
                                id="inputTo"
                                mask={dateMask}
                                name="to"
                                type="text"
                                value={
                                    to
                                        ? moment(to).format(dateFormat)
                                        : customDates.to ?? ""
                                }
                                className={classnames({
                                    "form-control": true,
                                    "is-invalid": errors.date.to,
                                })}
                                placeholder={dateFormat}
                                onChange={handleDateToManualChange}
                            />
                            {!!errors.date.to && (
                                <div className="form-error">
                                    {errors.date.to}
                                </div>
                            )}
                        </div>
                    </div>
                    <CustomDateRangePicker
                        from={from}
                        to={to}
                        onDayClick={handleDayClick}
                        disabledDays={disabledDays}
                    />
                </div>
            </div>
        );
    }

    function renderDropDown() {
        return (
            <FilterDropDown
                className={classnames(
                    {
                        "control-filter-content-date":
                            dateOptions.length > 1,
                    },
                    {
                        "control-filter-content-year-range":
                            isYearsRangeVisible && onYearsRangeChange,
                    },
                    {
                        "control-filter-content-datepicker":
                            isCustomViewVisible,
                    }
                )}
                expanded={expanded}
                value={
                    selectedFilterOption?.key === 2
                        ? customYearsRange
                        : customDateRange
                }
            >
                <div className="control-filter-range-list">
                    {onClearAll &&
                        <button
                            className="btn-link"
                            disabled={isResetDisabled}
                            onClick={handleResetToDefault}
                        >
                            Reset to default
                        </button>
                    }

                    {dateOptions.length > 1 && (
                        <ul className="control-filter-date-list">
                            {renderDateOptions()}
                        </ul>
                    )}
                </div>
                {isYearsRangeVisible &&
                    onYearsRangeChange &&
                    renderYearsRangeView()}
                {isCustomViewVisible && renderCustomView()}
            </FilterDropDown>
        );
    }

    function renderFilterView() {
        return (
            <>
                <FilterButton
                    title={renderTitle()}
                    expanded={expanded}
                    applied={isApplied}
                    selected={selected}
                    error={!!errors.year.from || !!errors.year.to || !!errors.date.from || !!errors.date.to}
                    onExpand={setExpanded}
                    onClearAll={handleResetToDefault}
                    disabled={disabled}
                />
                {expanded && renderDropDown()}
            </>
        );
    }

    function renderChartView() {
        return (
            <DropDownButton
                expanded={expanded}
                focused={false}
                title={renderTitle()}
                className="custom-drop-down-ghost"
                onClick={setExpanded}
                disabled={disabled}
            >
                {renderDropDown()}
            </DropDownButton>
        );
    }

    return (
        <ClickOutside
            className="control-filter-select"
            onClick={handleClickOutside}
        >
            {buttonView === 'filter' && renderFilterView()}
            {buttonView === 'chart' && renderChartView()}
        </ClickOutside>
    );
}

interface FilterDateRangeTitleProps {
    name?: string;
    selected?: DateFilterOption;
    customDateRange: DateRange;
    customYearsRange?: FilterRangeOption;
    maxRangeValue?: number;
}

export function FilterDateRangeTitle({
    name, selected, customDateRange, customYearsRange, maxRangeValue
}: FilterDateRangeTitleProps) {
    const formatName = () => name ? `${name}: ` : "";

    if (selected) {
        if (customDateRange.from || customDateRange.to) {
            const from = dateTimeUtils.parseDate(customDateRange.from, dateFormat);
            const to = dateTimeUtils.parseDate(customDateRange.to, dateFormat);
            const fromFormatted = moment(from).format(dateFormat);
            const toFormatted = moment(to).format(dateFormat);

            if (selected.formatTitle) {
                return <>{formatName()}{selected.formatTitle(fromFormatted, toFormatted)}</>;
            }

            if (from && to) {
                return <>{`${formatName()}${fromFormatted} - ${toFormatted}`}</>;
            }
        } else if (customYearsRange?.from || customYearsRange?.to) {
            const fromFormatted = String(customYearsRange.from || 0);
            const toFormatted = String(customYearsRange.to || maxRangeValue);

            return (
                <>
                    {formatName()}{selected.formatTitle?.(fromFormatted, toFormatted)
                        ?? `${fromFormatted}Y - ${toFormatted}Y`}
                </>
            )
        }

        return <>{`${formatName()}${selected.title}`}</>;
    }

    return <>{`${formatName()}All`}</>;
}
