import { BaseFieldProps, InputField } from "./InputField";
import { constants } from "../../../constants/constants";
import { DayPickerInput } from "../../controls/DayPickerInput";
import moment from "moment";

interface DateFieldProps extends BaseFieldProps {
    placeholder?: string;
    maximumDate?: Date;
    emptyIfNull?: boolean;
    dateFormat?: string;
    withCustomView?: boolean;
    disabled?: boolean;
}

export const DateField = ({
    placeholder = "MM/DD/YYYY",
    maximumDate,
    emptyIfNull,
    dateFormat = constants.dateShortFormat,
    withCustomView,
    disabled,
    ...props
}: DateFieldProps) => {
    return (
        <InputField
            {...props}
            renderElement={(formik, field, meta) => (
                <div className="data-item-col">
                    <div className="">
                        <DayPickerInput
                            disabled={disabled}
                            selectedDate={moment(
                                field.value || undefined
                            ).toDate()}
                            placeholder="MM/DD/YYYY"
                            maximumDate={maximumDate}
                            emptyIfNull={emptyIfNull}
                            dateFormat={dateFormat}
                            onDayChange={(day?: Date | string) =>
                                formik.setFieldValue(
                                    field.name,
                                    day
                                        ? moment(day)
                                              .startOf("day")
                                              .utcOffset(0, true)
                                              .format()
                                        : null
                                )
                            }
                            toMonth={maximumDate}
                            className="form-control"
                            withCustomView={withCustomView}
                        />
                    </div>
                    {meta.touched && meta.error && (
                        <div className="form-error">{meta.error}</div>
                    )}
                </div>
            )}
        />
    );
};
