import cn from 'classnames';
import { ExpandClearToggle } from "./ExpandClearToggle";

interface FilterButtonProps {
    title: React.ReactNode;
    expanded: boolean;
    applied?: boolean;
    selected?: boolean;
    error?: boolean;
    withExpandToogle?: boolean;
    onExpand: (expanded: boolean) => void;
    onClearAll: () => void;
    disabled?: boolean;
}

export function FilterButton({
    title,
    expanded,
    withExpandToogle = true,
    applied = false,
    selected = false,
    error = false,
    onExpand,
    onClearAll,
    disabled = false,
}: FilterButtonProps) {
    return (
        <div
            className={cn("control-filter-select-btn", {
                disabled,
                expanded,
                error,
                applied: applied && selected,
            })}
            onClick={() => onExpand(!expanded)}
        >
            <div className="control-filter-select-btn-content">
                {title}
            </div>
            {withExpandToogle &&
                <ExpandClearToggle
                    filterSelected={selected}
                    onExpand={() => onExpand(!expanded)}
                    onClear={onClearAll}
                />
            }
        </div>
    );
}
